import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Services.css';

const services = [
    {
        title: 'Coin Operated Washing machine',
        description: 'we are installing coin operated washing machine for labor in labor camps , and we are selling coin operated washing machine to the clients',
        image: '/images/machine.png',
        path: '/washing-machine',
    },
    {
        title: 'Blender',
        description: '2 Blenders In 1 - Multi,Serve Blender And Personal Blender Auto,Iq Technology - One,Touch Blending Programs Does All The Hard Work For You Durable And Powerful - 1400W Motor Blend, Max,Blend, Crush Pro Extractor Blades',
        image: '/images/juicer.JPG',
        path: '/juicer',
    },
    // Add more services as needed
];

const Services = () => {
    const navigate = useNavigate();

    const onServiceClick = (path) => {
        navigate(path);
    };

    return (
        <div className="services-container" id='services'>
            <h2 className="services-title">Our Services</h2>
            <div className="services-grid">
                {services.map((service, index) => (
                    <div className="service-card" key={index} onClick={() => onServiceClick(service.path)}>
                        <div className="service-image-container">
                            <img src={service.image} alt={service.title} className="service-image" />
                        </div>
                        <h3 className="service-title">{service.title}</h3>
                        <p className="service-description">{service.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Services;
