// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll'; // Using react-scroll for smooth scrolling
import '../styles/Navbar.css';
import { Link as RouterLink } from 'react-router-dom';

const Navbar = () => {
    const [click, setClick] = useState(false);
    const [navBackground, setNavBackground] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const handleScroll = () => {
        if (window.scrollY > 50) {
            setNavBackground(true);
        } else {
            setNavBackground(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav className={`navbar ${navBackground ? 'navbar-black' : 'navbar-black'}`}>
            <div className="navbar-container">
                <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                    <img src={`${process.env.PUBLIC_URL}/images/logog.png`} alt="Tech Inventor's" style={{ width: '187px', height: 'auto' }} className="navbar-logo-img" />
                </Link>


                <div className="menu-icon" onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>

                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className="nav-item">
                        <RouterLink to="/" className="nav-links" onClick={closeMobileMenu}>
                            Home
                        </RouterLink>
                    </li>
                    <li className="nav-item">
                        <RouterLink to="/projects" className="nav-links" onClick={closeMobileMenu}>
                            Projects
                        </RouterLink>
                    </li>
                    <li className="nav-item">
                        <RouterLink to="/services" className="nav-links" onClick={closeMobileMenu}>
                            Services
                        </RouterLink>
                    </li>
                    <li className="nav-item">
                        <RouterLink to="/about" className="nav-links" onClick={closeMobileMenu}>
                            About Us
                        </RouterLink>
                    </li>
                    <li className="nav-item">
                        <RouterLink to="/contact" className="nav-links" onClick={closeMobileMenu}>
                            Contact Us
                        </RouterLink>
                    </li>
                </ul>

            </div>
        </nav>
    );
};

export default Navbar;
