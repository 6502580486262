
import React from 'react';
import '../styles/About.css'

const About = () => {
    return (
        <div className="about-container" id='about'>
            <div className="about-content">
                <h2 className="about-title">About Us</h2>
                <div class="max-w-3xl mx-auto p-6 sm:p-8 bg-white shadow-md rounded-lg">
        <h1 class="text-3xl sm:text-4xl font-bold text-gray-900 mb-4">
            Top Suppliers of Coin Operated Washing Machines
        </h1>

        <h2 class="text-2xl sm:text-3xl font-semibold text-blue-700 mb-3">
            Goldenwave: Your Trusted Partner for Laundry Solutions
        </h2>

        <p class="text-gray-700 mb-5">
            Goldenwave is Dubai’s leading distributor of high-quality coin-operated washing machines for the MENA region. We have built a reputation for excellence by offering matchless service, best-quality machines, and comprehensive support to our valuable corporate clientele, business partners, and end users.
        </p>

        <h2 class="text-2xl sm:text-3xl font-semibold text-blue-700 mb-3">
            Why Goldenwave Should be Your Ultimate Choice?
        </h2>

        <p class="text-gray-700 mb-5">
            We are not just claiming to be the best; our success story speaks for itself. Our organization has been consistently ranked as Dubai’s leading distributor for coin-operated washing machines and dryers all across Dubai. We have a proven track record of high annual sales.
        </p>

        <p class="text-gray-700 mb-5">
            This high ranking has positioned Goldenwave as a top industry leader, reflecting that our customers hold profound trust in the quality of services we offer. With a strong track record in Dubai’s market, we are the trusted choice for our excellent services and high annual sales.
        </p>

        <h2 class="text-2xl sm:text-3xl font-semibold text-blue-700 mb-3">
            Revolutionize Your Coin Laundry Business with Goldenwave
        </h2>

        <p class="text-gray-700 mb-5">
            Goldenwave isn’t just limited to selling machines; we are about transforming your laundry business. Here is how:
        </p>

        <ul class="list-disc list-inside mb-5 space-y-2">
            <li>
                <strong class="font-semibold">Offer Customized Solutions to You:</strong> 
                Our expert team works closely with your team to understand your specific business needs, everything from capacity requirements to space limitations. Based on your specific needs, we craft customized solutions and packages that align perfectly with your vision and organizational goals.
            </li>
            <li>
                <strong class="font-semibold">Fast & Reliable Support:</strong> 
                Machine downtime is costly. That is why we offer a dedicated professional support team to answer your questions related to the coin laundry business, addressing any of your issues promptly. You can count on us for quick and reliable support to run your laundry operation smoothly and consistently.
            </li>
            <li>
                <strong class="font-semibold">On-Time Delivery:</strong> 
                We are committed to offering on-time delivery, ensuring your machines are delivered when promised without any delay. We minimize disruptions and help you get your coin revenues flowing faster.
            </li>
        </ul>

        <p class="text-gray-700 mb-5">
            By partnering with Vending Ways, you are not just buying coin washing equipment; you are actually investing in a 360-degree laundry solution designed to maximize your business success.
        </p>

        <h2 class="text-2xl sm:text-3xl font-semibold text-blue-700 mb-3">
            Are you ready for coin-minting?
        </h2>
    </div>

                <h3 className="about-subtitle">Our Values</h3>
                <ul className="about-values">
                    <li>🌟 <strong>Integrity</strong>: We uphold the highest standards of integrity in all our actions.</li>
                    <li>🚀 <strong>Innovation</strong>: We pursue innovation to deliver cutting-edge solutions.</li>
                    <li>🤝 <strong>Customer Focus</strong>: Our clients are at the center of everything we do.</li>
                    <li>🌍 <strong>Sustainability</strong>: We are committed to sustainable practices in our operations.</li>
                </ul>
            </div>
        </div>
    );
};
export default About;
