import React, { useState, useEffect } from 'react';
import '../styles/Navbar.css';
import '../styles/washing.css';


const WashingMachineDetails = () => {
    const washingMachines = [
      {
        id: 1,
        imageUrl: '/images/05.png',
        description: [
          'This powerful antibacterial liquid will remove bacteria, dirt, odours and limescale for a more hygienic and fresh laundry. Keeping your washing machine clean as well as your clothes is an important factor to consider when trying to fight bacteria.',
          'A washing machine also know as a washer is a machine that washes dirty clothes. It contains a barrel into which the clothes are placed. This barrel is filled with water, and then rotated very quickly by the use of a motor to make the water remove dirt from the clothes.'
        ],
      },
      {
        id: 2,
        imageUrl: '/images/09.png',
        description: [
          'Top-load washing machine with multiple wash settings and user-friendly controls.',
          'Designed for convenience, it provides a variety of washing options for different fabrics.'
        ],
      },
      {
        id: 3,
        imageUrl: '/images/06.png',
        description: [
          'Compact washing machine ideal for apartments or small spaces.',
          'It is lightweight, portable, and easy to store, yet powerful enough for daily laundry needs.'
        ],
      },
      {
        id: 4,
        imageUrl: '/images/09.png',
        description: [
          'Portable washing machine suitable for small spaces or on-the-go laundry needs.',
          'Efficient and compact, it is perfect for campers, travelers, or small apartment dwellers.'
        ],
      },
    ];
  
    return (
      <div className="washing-machine-container">
        <h2>Washing Machines</h2>
        <div className="machine-list">
          {washingMachines.map((machine) => (
            <div key={machine.id} className="machine-item">
              <img src={machine.imageUrl} alt="Washing Machine" className="machine-image" />
              <div className="machine-description">
                <p>{machine.description[0]}</p>
                <p>{machine.description[1]}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default WashingMachineDetails;
