import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa'; // Ensure you import the WhatsApp icon
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Projects from './pages/Projects';
import About from './pages/About';
import Contact from './pages/Contact';
import Services from './pages/Services';
import WashingMachineDetails from './components/WashingMachineDetails';
import JuicerMachine from './components/JuicerMachine';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        {/* Dynamic routes for each service */}
        <Route path="/washing-machine" element={<WashingMachineDetails />} />
        <Route path="/juicer" element={<JuicerMachine />} />
      </Routes>
      {/* Add WhatsApp icon at the bottom or wherever you need it */}
      <div style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000 }}>
        <a href="https://wa.me/+971509066588" target="_blank" rel="noopener noreferrer">
          <FaWhatsapp size={60} color="#25D366" />
        </a>
      </div>
    </Router>
  );
}

export default App;
