import React, { useState, useEffect } from 'react';
import '../styles/Navbar.css';
import '../styles/washing.css';


const JuicerMachine = () => {
    const washingMachines = [
      {
        id: 1,
        imageUrl: '/images/j1.JPG',
        description: [
          'A juicer, also known as a juice extractor, is a tool used to extract juice from fruits, herbs, leafy greens and other types of vegetables in a process called juicing. ',
          'It crushes, grinds, and/or squeezes the juice out of the pulp'
        ],
      },
      {
        id: 2,
        imageUrl: '/images/j2.jpg',
        description: [
          'A juicer is an electrical appliance for extracting juice from fruit and vegetables. This powerful electric juicer automatically separates the pulp from the juice.',
          'Designed for convenience, it provides a variety of washing options for different fabrics.'
        ],
      },
      {
        id: 3,
        imageUrl: '/images/j3.jpg',
        description: [
          'No More Lumps: Say goodbye to chunky shakes! Our juicer blender has a strong motor and sharp blades that turn fruits, veggies, and ice into smooth and delicious drinks.',
          'Quick Cleanup: Cleaning up is a breeze. You can easily wash the parts, so you spend less time cleaning and more time enjoying your creations.'
        ],
      },
      {
        id: 4,
        imageUrl: '/images/j4.JPG',
        description: [
          'Fits Any Kitchen: Its compact and looks good on your kitchen counter. Plus, it doesnt take up much space.',
          'Take It Anywhere: Make your drink right in the bottle, so you can take it with you wherever you go – to the gym, work, or out and about.'
        ],
      },
      {
        id: 5,
        imageUrl: '/images/j5.JPG',
        description: [
          'Product Description',
          'Easy-To-Use Juicer Blender: Our juicer blender makes healthy drinks and smoothies super simple. Just put in your ingredients and press a button to blend!'
        ],
      },
      {
        id: 6,
        imageUrl: '/images/j6.JPG',
        description: [
          'Fits Any Kitchen: Its compact and looks good on your kitchen counter. Plus, it doesnt take up much space.',
          'Take It Anywhere: Make your drink right in the bottle, so you can take it with you wherever you go – to the gym, work, or out and about.'
        ],
      },
      {
        id: 7,
        imageUrl: '/images/j7.JPG',
        description: [
          'Healthy Eating Made Easy: Want to eat better? This machine helps you make yummy, nutritious drinks effortlessly. Its like having your own healthy kitchen assistant.',
          'Take It Anywhere: Make your drink right in the bottle, so you can take it with you wherever you go – to the gym, work, or out and about.'
        ],
      },
      {
        id: 8,
        imageUrl: '/images/j8.JPG',
        description: [
          'We use knife-free design, when the lid is opened, the machine stops running, which not only improves the efficiency of juicing, but also ensures safety!',
          'Take It Anywhere: Make your drink right in the bottle, so you can take it with you wherever you go – to the gym, work, or out and about.'
        ],
      },
      {
        id: 9,
        imageUrl: '/images/j9.JPG',
        description: [
          'We use knife-free design, when the lid is opened, the machine stops running, which not only improves the efficiency of juicing, but also ensures safety!',
          'Take It Anywhere: Make your drink right in the bottle, so you can take it with you wherever you go – to the gym, work, or out and about.'
        ],
      },
    ];
  
    return (
      <div className="washing-machine-container">
        <h2>Juicer Machines</h2>
        <div className="machine-list">
          {washingMachines.map((machine) => (
            <div key={machine.id} className="machine-item">
              <img src={machine.imageUrl} alt="Washing Machine" className="machine-image" />
              <div className="machine-description">
                <p>{machine.description[0]}</p>
                <p>{machine.description[1]}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default JuicerMachine;
